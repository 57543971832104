import React from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import { layout, form } from './winner.module.scss';

import { IPage } from '../../../models/page.model';

import WinterLayout from '../../../layouts/winter-layout';
import WinterContestWinnerForm from '../../../components/organisms/winter-contest-winner-form';

interface IWinterContestWinnerPage {
    readonly data: {
        page: IPage | null;
    };
}

const Winner: React.FC<IWinterContestWinnerPage> = ({ data }) => {
    const { page } = data;
    const clientQuery = new QueryClient();

    return (
        <QueryClientProvider client={clientQuery}>
            <WinterLayout className={layout} page={page} pageType={'winner'}>
                <WinterContestWinnerForm className={form} />
            </WinterLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Winner;
