import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export interface IWinterContestWinnerValues {
    token: string;
    firstname: string;
    lastname: string;
    birthday: string;
    email: string;
    phone: string;
    street: string;
    houseNumber: string;
    apartmentNumber: string;
    zipCode: string;
    city: string;
    recaptcha: string;
}

export function getInitialValues(token: string): IWinterContestWinnerValues {
    return {
        token: token,
        firstname: '',
        lastname: '',
        birthday: '',
        email: '',
        phone: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        zipCode: '',
        city: '',
        recaptcha: '',
    };
}

export function getValidationSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape({
        firstname: Yup.string().required(t('form.required.error')),
        lastname: Yup.string().required(t('form.required.error')),
        birthday: Yup.date()
            .nullable()
            .test('birthday', t('form.age.error'), function (value, ctx) {
                if (value) {
                    const date = new Date(value);
                    const now = new Date();
                    const OF_AGE = now.getFullYear() - date.getFullYear() > 18;
                    const YEARS_VALID = now.getFullYear() - date.getFullYear() === 18;
                    const MONTHS_VALID = now.getMonth() - date.getMonth() >= 0;
                    const DAYS_VALID = now.getDate() - date.getDate() >= 0;

                    const valid = OF_AGE || (YEARS_VALID && MONTHS_VALID && DAYS_VALID);
                    return !valid ? ctx.createError() : valid;
                }
                return ctx.createError();
            })
            .required(t('form.required.error')),
        email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
        phone: Yup.string().required(t('form.required.error')),
        street: Yup.string().required(t('form.required.error')),
        houseNumber: Yup.string().required(t('form.required.error')),
        apartmentNumber: Yup.string().max(16, t('form.apartmentNumber.length')),
        zipCode: Yup.string().required(t('form.required.error')),
        city: Yup.string().required(t('form.required.error')),
        recaptcha: Yup.string().required(),
    });
}
